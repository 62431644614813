import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height:100vh;
  width: 100%;
`
export const RightBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  align-items: center;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 384px;
  @media screen and (max-width: 420px){
    width:  100%;
  }
`

export const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const LinkLang = styled.p`
  color: var(--Text-brand, #006FEE);
  text-overflow: ellipsis;
  /* sm/Semi Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const Title = styled.h2`
  color: var(--Text-primary, #11181C);
  /* 3xl/Semi Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-3xl, 30px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Font-line-height-3xl, 36px); /* 120% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const SubTitle = styled.h3`
  color: var(--Text-quaternary, #71717A);

  /* sm/Medium */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:4px;
  text-align: left;
  margin-bottom: 20px;
`

export const ForgotPassword = styled.a`
  overflow: hidden;
  color: var(--Text-quaternary, #71717A);
  text-overflow: ellipsis;

  /* sm/Semi Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`

export const HelpButton = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  width: 160px;
  box-sizing: border-box;
  justify-content: center;
  padding: 10px;
  border-radius: var(--Radius-xl, 12px);
  background: var(--Component-colors-Button-primary-04, rgba(212, 212, 216, 0.40));
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
`

export const HelpText = styled.div`
  color: var(--Text-base-black, #000);
  /* sm/Semi Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const NoAcc = styled.p`
  color: var(--Text-primary, #11181C);

  /* sm/Medium */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
`

export const GoAction = styled.a`
  overflow: hidden;
  color: var(--Text-brand, #006FEE);
  text-overflow: ellipsis;

  /* sm/Semi Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-sm, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Font-line-height-sm, 20px); /* 142.857% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
  text-decoration: none;
`