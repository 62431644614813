import React from 'react'
import {
  Container,
  EllBottom,
  EllTopLeft,
  EllTopRight,
  Logo,
  Title1,
  Title2,
  TextBlock,
} from './styles'
import LogoIc from './logo.svg'
import LockIc from './ic-lock.svg'

const AuthBlock = () => {
  return(
    <Container>
      <EllBottom />
      <Logo src={LogoIc} alt='LogoIc'/>
      <EllTopLeft />
      <EllTopRight />
      <TextBlock>
        <Title1>
          Звонки.
          <Title2>Безопасно</Title2>
          <Title1>Анонимно.</Title1>
        </Title1>
        <img style={{zIndex:2}} src={LockIc}/>
      </TextBlock>

    </Container>
  )
}

export default AuthBlock

