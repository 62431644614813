import React, { FC, useState, useEffect } from "react";
import {
  Container,
  StyledLogo,
  LogoWrapper,
  LogoText,
  Category,
  SideBar,
  SideCategoryWrapper,
  SideBarItemsWrapper,
  SideBarItem,
  SideBarItemText
} from './styles'
import icons from './icons'
import { useWindowSize } from "../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import Footer from './footer'
const sideBarItems = [
  {
    title:'Основное меню',
    items:[
      {title:'Главная', icon:icons.IcHome, slug:'/'},
      {title:'Журнал звонков', icon:icons.IcList, slug:'call-list'},
      {title:'Пользователи', icon:icons.IcUserGroup, slug:'users'},
      {title:'SIP-транки', icon:icons.IcSipTrunks, slug:'sip-trunks'},
      {title:'Финансы', icon:icons.IcWallet, slug:'finance'},
      {title:'Общие настройки', icon:icons.IcSettings, slug:'settings'},
    ]
  },
  {
    title:'Дополнительно',
    items:[
      {title:'База знаний', icon:icons.IcInfo, slug:'guide'},
      {title:'Новости', icon:icons.IcNews, slug:'news'},
      {title:'Поддержка в Telegram', icon:icons.IcTelegram, slug:'support-telegram'},
    ]
  }
]

const SideBarComponent:FC = () => {
  const [width, height] = useWindowSize();
  const [isSmall, setIsSmall] = useState<boolean>(width < 1300)
  const location = useLocation()
  console.log('location', location);
  useEffect(() => {
    if(width < 1300){
      setIsSmall(true)
    } else {
      setIsSmall(false)
    }
  },[width])
  return(
    <Container isSmall={isSmall}>
      <SideBar>
        <LogoWrapper>
          <StyledLogo src={icons.Logo}/>
          {!isSmall &&
            <LogoText>
              Freenew
            </LogoText>
          }
        </LogoWrapper>
        {sideBarItems?.map((item) => (
          <SideCategoryWrapper>
            {!isSmall &&
              <Category>{item?.title}</Category>
            }
            <SideBarItemsWrapper>
              {item?.items?.map((sideBarItem:any, index:any) => (
                <SideBarItem active={index===0} href={sideBarItem.slug}>
                  <img style={{minWidth:'16px', minHeight:'16px'}} src={sideBarItem.icon}/>
                  {!isSmall &&
                    <SideBarItemText>
                      {sideBarItem?.title}
                    </SideBarItemText>
                  }
                </SideBarItem>
              ))}
            </SideBarItemsWrapper>
          </SideCategoryWrapper>
        ))}
      </SideBar>
      <Footer/>
    </Container>
  )
}

export default SideBarComponent