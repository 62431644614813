import ArrowsDown from './arrows-down.svg'
import IcHome from './ic-home.svg'
import IcInfo from './ic-info.svg'
import IcList from './ic-list.svg'
import IcNews from './ic-news.svg'
import IcPlus from './ic-plus.svg'
import IcSettings from './ic-settings.svg'
import IcSipTrunks from './ic-sip-trunks.svg'
import IcTelegram from './ic-telegram.svg'
import IcUserGroup from './ic-usergroup.svg'
import IcWallet from './ic-wallet.svg'
import Logo from './logo.svg'

export default {
  ArrowsDown,
  IcHome,
  IcInfo,
  IcList,
  IcNews,
  IcPlus,
  IcSettings,
  IcSipTrunks,
  IcTelegram,
  IcUserGroup,
  IcWallet,
  Logo,
}