import { FunctionComponent } from 'react';
import styles from './styles.module.css';


const Total:FunctionComponent = () => {
  	return (
    		<div className={styles.frameParent}>
      			<div className={styles.checkboxGroupPricingWrapper}>
        				<div className={styles.checkboxGroupPricing}>
          					<div className={styles.left}>
            						<div className={styles.wrapper}>
              							<div className={styles.div}>Активные звонки</div>
            						</div>
            						<div className={styles.middle}>
              							<div className={styles.div1}>4</div>
              							<div className={styles.text} />
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className={styles.checkboxGroupPricingWrapper}>
        				<div className={styles.checkboxGroupPricing1}>
          					<div className={styles.left}>
            						<div className={styles.wrapper}>
              							<div className={styles.div}>Всего операторов</div>
            						</div>
            						<div className={styles.middle1}>
              							<div className={styles.div3}>24</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className={styles.checkboxGroupPricingWrapper}>
        				<div className={styles.checkboxGroupPricing2}>
          					<div className={styles.left}>
            						<div className={styles.wrapper}>
              							<div className={styles.div}>Операторы онлайн</div>
            						</div>
            						<div className={styles.middle}>
              							<div className={styles.div1}>6</div>
              							<div className={styles.text} />
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>);
};

export default Total;
