import { createRoot } from "react-dom/client";
import {
  SignIn,
  SignUp,
  ResetPassword,
  CreateNewPassword,
  HomePage,
} from '../pages'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { SideBar } from "../components";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="flex h-full w-full">
        <SideBar/>
        <HomePage />
      </div>
    ),
    children: [
      {
        path: "call-list",
        element: <>'call-list'</>,
      },
      {
        path: "users",
        element: <>'users'</>,
      },
      {
        path: "sip-trunks",
        element: <>'sip-trunks'</>,
      },
      {
        path: "finance",
        element: <>'finance'</>,
      },
      {
        path: "settings",
        element: <>'settings'</>,
      },
      {
        path: "guide",
        element: <>'guide'</>,
      },
      {
        path: "news",
        element: <>'news'</>,
      },
    ],
  },
  {
    path: "sign-in",
    element: <SignIn />,
  },
  {
    path: "sign-up",
    element: <SignUp />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "create-new-password",
    element: <CreateNewPassword />,
  },
]);


export default router
