import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height:100%;
  box-sizing: border-box;
  width: 100%;
  padding: 50px;
  justify-content: space-between;
  position: relative;
  background-color: #006FEE;
  overflow: hidden;
  @media screen and (max-width: 920px){
    display: none;
  }
`

export const EllBottom = styled.div`
  position: absolute;
  left: -829px;
  z-index: 3;
  bottom:-702px;
  width: 1795px;
  height: 1214px;
  border-radius: 1795px;
  background: var(--BG-tertiary, #F4F4F5);
  filter: blur(110px);
`

export const EllTopLeft = styled.div`
  position: absolute;
  top:66px;
  left: -302px;
  width: 741px;
  height: 741px;
  border-radius: 741px;
  background: #1A48EE;
  z-index: 2;
  filter: blur(200px);
`
export const EllTopRight = styled.div`
  position: absolute;
  top:-315px;
  right: -530px;
  width: 762px;
  height: 762px;
  border-radius: 762px;
  background: #A8BFFF;
  filter: blur(200px);
`
export const Logo = styled.img`
  width:168px;
`

export const Title1 = styled.p`
  //styleName: 6xl/Bold;
  color: var(--Text-primary, #11181C);

  /* 6xl/Bold */
  font-family: var(--Font-family-Inter, Inter);
  font-size: var(--Font-size-6xl, 60px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--Font-line-height-6xl, 60px); /* 100% */
  letter-spacing: var(--Font-letter-spacing-xs, 0px);
  z-index: 2;


`
export const Title2 = styled.p`
  //styleName: 6xl/Bold;
  font-family: var(--FontfamilyInter);
  font-size: var(--Fontsize6xl);
  font-weight: 700;
  line-height: var(--Fontline-height6xl);
  letter-spacing: var(--Fontletter-spacingxs);
  text-align: left;
  color: #006FEE;
`
export const TextBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  z-index: 4;
`