import React, {FC} from 'react';
import styles from './styles.module.css'
import ArrowIc from './lucide｜arrow-up.svg'

const MetricsBlock:FC = () => {
  return (
    <div className={styles.totalOutgoingCalls}>
    <div className={styles.left}>
    <div className={styles.parent}>
    <div className={styles.div}>Исходящие звонки</div>
    <div className={styles.top}>
    <img className={styles.lucidearrowUpIcon} alt="" src={ArrowIc} />
    <div className={styles.div1}>4%</div>
    </div>
    </div>
    <div className={styles.middle}>
    <div className={styles.div2}>3,335</div>
    <div className={styles.div3}>2,840</div>
    </div>
    </div>
    <div className={styles.graphic}>
    <div className={styles.items}>
    <div className={styles.item} />
    <div className={styles.item1} />
    <div className={styles.item2} />
    <div className={styles.item3} />
    <div className={styles.item4} />
    <div className={styles.item5} />
    <div className={styles.item6} />
    </div>
    <div className={styles.dates}>
    <div className={styles.div4}>20 сентября</div>
    <div className={styles.div4}>27 сентября</div>
    </div>
    </div>
    </div>);
}

export default MetricsBlock