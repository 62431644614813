import { FunctionComponent } from 'react';
import styles from './styles.module.css';
import icons from '../icons';
import {Button} from "@nextui-org/button";


const Footer:FunctionComponent = () => {
  	return (
    		<div className={styles.footer}>
      			<div className={styles.container}>
        				<div className={styles.container}>
          					<div className={styles.navFeaturedCard}>
            						<div className={styles.headina}>
              							<div className={styles.text}>Баланс</div>
              							<div className={styles.supportingText}>$4.11</div>
            						</div>
            						<Button size="sm" color="primary" onPress={() => {}}>
                        Пополнить
                        </Button>
          					</div>
        				</div>
      			</div>
      			<div className={styles.user}>
        				<div className={styles.block}>
          					<div className={styles.spanflex}>
            						<div className={styles.imgJunior}>H</div>
          					</div>
          					<div className={styles.divinlineFlex}>
            						<div className={styles.text}>hetzner561</div>
            						<div className={styles.id405104}>ID: 405104</div>
          					</div>
        				</div>
        				<img className={styles.vuesaxlineararrowsDownIcon} alt="" src={icons.ArrowsDown} />
      			</div>
    		</div>);
};

export default Footer;
