import * as React from "react";
// 1. import `NextUIProvider` component
import {NextUIProvider} from "@nextui-org/react";
import router from './router'
import {
  RouterProvider,
} from "react-router-dom";
// import { SignIn } from "./pages";

function App() {
  // 2. Wrap NextUIProvider at the root of your app
  return (
    <NextUIProvider>
      <RouterProvider router={router} />
      {/* <SignIn/> */}
    </NextUIProvider>
  );
}

export default App