import { FunctionComponent } from 'react';
import styles from './styles.module.css';
import {Switch, Input, Button} from "@nextui-org/react";
import IcPhone from './ic-phone.svg'
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const Phone:FunctionComponent = () => {
  	return (
    		<div className={styles.phone}>
      			<div className={styles.checkboxParent}>
        				<div className={styles.checkbox}>
          					<div className={styles.checkboxBase} />
                    <Switch size='sm' color='primary' defaultSelected aria-label="Automatic updates"/>

          					<div className={styles.right}>
            						<div className={styles.text}>Подмена номера</div>
          					</div>
        				</div>
        				<div className={styles.inputBaseParent}>
                  <Input type="email" variant={'bordered'} label="Номер телефона" placeholder="Введите номер" />
          					<div className={styles.price}>

            						<div className={styles.napravlenie1}>
              							<div className={styles.div5}>
                								<p className={styles.p}>{`Стоимость звонка `}</p>
                								<p className={styles.p}>будет продемонстрирована</p>
                								<p className={styles.p}>после ввода префикса.</p>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className={styles.numbersCall}>
        				<div className={styles.line}>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>1</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>2</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>3</div>
            						</div>
          					</div>
        				</div>
        				<div className={styles.line}>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>4</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>5</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>6</div>
            						</div>
          					</div>
        				</div>
        				<div className={styles.line}>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>7</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>8</div>
            						</div>
          					</div>
          					<div className={styles.button2}>
            						<div className={styles.textPadding}>
              							<div className={styles.button3}>9</div>
            						</div>
          					</div>
        				</div>
        				<div className={styles.button20}>
          					<div className={styles.lucidecircle} />
          					<img className={styles.icPhoneIcon} alt="" src={IcPhone} />
          					<div className={styles.lucidecircle} />
        				</div>
      			</div>
    		</div>);
};

export default Phone;
