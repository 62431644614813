import React, {useState} from "react";
import {
  Container,
  Form,
  RightBlock,
  Head,
  LinkLang,
  TitleWrapper,
  Title,
  SubTitle,
  ForgotPassword,
  BottomBlock,
  HelpButton,
  HelpText,
  NoAcc,
  GoAction,
} from '../sign-in/styles'
import {AuthBlock} from '../../components'
import {Input} from "@nextui-org/input";
import {Button} from "@nextui-org/button";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import GlobeIc from './globe.svg'
import {Checkbox} from "@nextui-org/react";
import IcFaq from './circle-help.svg'
import TelegaIc from './ic-telegram.svg'
import EyeIc from './eye.svg'
import { useNavigation, useNavigate } from "react-router-dom";


export default function Page (){
  const [t] = useTranslation();
  const [initialValues, setInitialValues ] = useState<any>({
    email:'',
    password:''
  })
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const navigation = useNavigate()

  const onSubmit = (values:any) => {
    navigation('/create-new-password')
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      email: yup.string().email(`${t("NOT_VALID_EMAIL")}`).required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const validateEmail = (value:any) => {
    if (!value) {
      return 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return 'Invalid email address';
    }
    return null;
  };
  console.log('formikProps',formikProps);
  return(
    <Container>
      <AuthBlock/>
      <RightBlock>
        <Head>
          <img src={GlobeIc}/>
          <LinkLang>Switch to English</LinkLang>
        </Head>
        <Form>
          <TitleWrapper>
            <Title>Сброс пароля</Title>
            <SubTitle>Введите Email, связанный с вашим аккаунтом и мы отправим вам ссылку на сброса поля</SubTitle>
          </TitleWrapper>
          <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
            <Input
              variant="bordered"
              name="email"
              label="Email"
              placeholder="Введите свою почту"
              value={formikProps.values.email}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              //@ts-ignore
              // errorMessage={formikProps.touched.email && formikProps.errors.email ? formikProps.errors.email : undefined} // Отображение сообщения об ошибке
              // validate={validateEmail} // Функция кастомной валидации
              // isInvalid={formikProps.touched.email && !!formikProps.errors.email} // Установка ошибки для изменения стиля
              // validationBehavior="aria" // Использование ARIA атрибутов для доступности
            />
          </div>
          <div style={{marginTop:'20px', display:'flex', flexDirection:'column', gap:'12px'}}>
            {/* @ts-ignore */}
            <Button size="lg" color="primary" onPress={handleSubmit}>
              Сбросить пароль
            </Button>
            <Button size="lg" color="default" onPress={() => navigation(-1)}>
              Вернуться
            </Button>
          </div>
        </Form>
        <BottomBlock>
          <HelpButton>
            <img src={IcFaq}/>
            <HelpText>
              FAQ
            </HelpText>
          </HelpButton>
          <HelpButton>
            <img src={TelegaIc}/>
            <HelpText>
              Telegram
            </HelpText>
          </HelpButton>
        </BottomBlock>
      </RightBlock>
    </Container>
  )
}
