import React, { FC } from "react";
import {
  SideBar,
} from '../../components'
import {
  Container,
  MainBlock,
  PhoneBlock,
  PageTitle,
} from './stlyes'
import styles from './styles.module.css';
import {Tabs, Tab, Card, CardBody, DateRangePicker} from "@nextui-org/react";
import {
  MetricsBlock,
  Total,
  OutCalls,
  Phone,
} from './components'
const tabsDates = [
  'Сегодня',
  'Неделя',
  'Месяц',
  'Год'
]
const HomePage:FC<any> = () => {

  return(
    <Container>
      <MainBlock>
        <PageTitle>
          Главная
        </PageTitle>
        <Total/>
        <div style={{display:'flex', alignItems:'center', gap:'16px'}}>
          <div style={{whiteSpace:'nowrap'}} className="text-black text-sm font-normal font-['Inter'] leading-tight">Обзор за</div>
          <Tabs aria-label="Options">
            {tabsDates?.map((tab) => (
              <Tab key={tab} title={tab}>
              </Tab>
            ))}
          </Tabs>
          <DateRangePicker 
            className="max-w-xs" 
          />
        </div>
        <MetricsBlock/>
        <OutCalls/>
      </MainBlock>
      <PhoneBlock>
        <Phone/>
      </PhoneBlock>
      
    </Container>
  )
}

export default HomePage
