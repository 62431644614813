import { FunctionComponent } from 'react';
import styles from './styles.module.css';
import IcUser from './ic-user.svg';
import {Button} from "@nextui-org/button";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";

const users = [
  { name: 'user1', calls: 364 },
  { name: 'hittment', calls: 201 },
  { name: 'cerebradm', calls: 104 },
  { name: 'serb1', calls: 32 },
  { name: 'twister', calls: 8 },
  { name: 'user1', calls: 364 }, // Example of a repeated user
];

const OutCalls: FunctionComponent = () => {
  // Create a map to keep track of unique users and their call counts
  const userMap: Record<string, number> = {};
  
  // Populate the map with user data
  users.forEach(user => {
    if (userMap[user.name]) {
      userMap[user.name] += user.calls; // Sum calls if user already exists
    } else {
      userMap[user.name] = user.calls; // Initialize with call count
    }
  });

  // Convert the map back to an array of user objects
  const uniqueUsers = Object.entries(userMap).map(([name, calls]) => ({ name, calls }));

  return (
    <div className={styles.totalOutgoingCalls}>
      <div className={styles.left}>
        <div className={styles.div}>Объем исходящих звонков по пользователям</div>
        <div className={styles.wrapper}>
          <div className={styles.div1}>Исходящие звонки</div>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tableheader}>
          <div className={styles.text}>
            <div className={styles.text1}>Пользователь</div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>Исходящие звонки</div>
          </div>
        </div>
        <div className={styles.rowwrapper}>
          {uniqueUsers.map((user, index) => (
            <div className={styles.tablerow} key={index}>
              <div className={styles.tablecell}>
                <img className={styles.icUserIcon} alt="" src={IcUser} />
                <div className={styles.text4}>
                  <div className={styles.div1}>{user.name}</div>
                </div>
              </div>
              <div className={styles.tablecell1}>
                <div className={styles.text4}>
                  <div className={styles.div1}>{user.calls}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
        <Button style={{backgroundColor:'rgba(0, 111, 238, 0.06)', color: '#006fee'}} size="md" color="secondary" onPress={() => {}}>
          Посмотреть подробную статистику
        </Button>
    </div>
  );
};

export default OutCalls;
