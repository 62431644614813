import styled from 'styled-components'


export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
export const MainBlock = styled.div`
  padding: 32px 48px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

`

export const PhoneBlock = styled.div`
  padding: 32px 24px 32px 0px;
  display: flex;


`

export const PageTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #11181C;
`

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  padding: 16px;
  border-radius: 8px;
`