import styled from 'styled-components'

type ItemProps = {
  active?:boolean;
}

export const Container = styled.div<{isSmall?:boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({isSmall}) => isSmall ? '24px 8px' : '24px'};
  width: ${({isSmall}) => isSmall ? '56px' : '288px'};
  height: 100vh;
  box-sizing: border-box;
  align-items: flex-start;
  border-right: 1px solid var(--Border-primary, #E5E7EBB8);
  /* transition: width 0.2s ease-in-out; */
  overflow: hidden;
`

export const StyledLogo = styled.img`
  width: 30px;
  height: 30px;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width:1300px){
    justify-content: center;
  }
`

export const LogoText = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: -0.6px;
  color: #000000;
`
export const Category = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #71717A;
`
export const SideBar = styled.div`
  gap:24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SideBarItemsWrapper = styled(SideBar)`
  gap:4px;
`
export const SideCategoryWrapper = styled(SideBar)`
  gap:8px;
`
export const SideBarItem = styled.a<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  gap: 8px;
  background: ${({active}) => active ? '#F4F4F5' : 'transparent'};
  transition: all 0.2s ease-in-out;
  border-radius: 14px;
  &:hover{
    background: #F4F4F5;
    cursor: pointer;
  }

`
export const SideBarItemText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  line-height: 19px;
  color: #11181C;
`