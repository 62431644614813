import React, {useState} from "react";
import {
  Container,
  Form,
  RightBlock,
  Head,
  LinkLang,
  TitleWrapper,
  Title,
  SubTitle,
  ForgotPassword,
  BottomBlock,
  HelpButton,
  HelpText,
  NoAcc,
  GoAction,
} from '../sign-in/styles'
import {AuthBlock} from '../../components'
import {Input} from "@nextui-org/input";
import {Button} from "@nextui-org/button";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import GlobeIc from './globe.svg'
import {Checkbox} from "@nextui-org/react";
import IcFaq from './circle-help.svg'
import TelegaIc from './ic-telegram.svg'
import EyeIc from './eye.svg'
import SuccessIc from './success.svg'

export default function Page (){
  const [t] = useTranslation();
  const [initialValues, setInitialValues ] = useState<any>({
    passwordConfirm:'',
    password:''
  })
  const [isVisible, setIsVisible] = React.useState(false);
  const [isCreated, setIsCreated] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleCreated = () => setIsCreated(!isCreated);

  const onSubmit = (values:any) => {
    toggleCreated()
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      password: yup.string()
      // .required(`${t('REQUIRED_FIELD')}`)
      .max(24, `${t('PASSWORD_VALIDATE_MAX')}`),
      passwordConfirm: yup.string()
      // .required(`${t('REQUIRED_FIELD')}`)
      .max(24, `${t('PASSWORD_VALIDATE_MAX')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const validateEmail = (value:any) => {
    if (!value) {
      return 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return 'Invalid email address';
    }
    return null;
  };
  console.log('formikProps',formikProps);
  return(
    <Container>
      <AuthBlock/>
      <RightBlock>
        <Head>
          <img src={GlobeIc}/>
          <LinkLang>Switch to English</LinkLang>
        </Head>
        {isCreated ?
          <Form style={{
            display:'flex',
            flexDirection:'column',
            gap:'16px',
            textAlign:'center',
            width:'384px',
            }}>
            <img src={SuccessIc}/>
            <Title style={{whiteSpace:'nowrap'}}>Пароль успешно изменен</Title>
            <div style={{marginTop:'4px'}}>
              <Button style={{width:'100%'}} size="lg" color="primary" onPress={toggleCreated}>
              Перейти на страницу авторизации
              </Button>
            </div>

          </Form>
        :
          <Form>
            <TitleWrapper>
              <Title>Создайте новый пароль</Title>
              <SubTitle>Ваш новый пароль должен отличаться от паролей, что использовались ранее</SubTitle>
            </TitleWrapper>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
              <Input
                variant="bordered"
                name="password"
                label="Пароль"
                placeholder="Введите ваш пароль"
                value={formikProps.values.password}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                endContent={
                  <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
                    {isVisible ? (
                      // <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      <img src={EyeIc}/>
                    ) : (
                      // <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      <img src={EyeIc}/>
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                //@ts-ignore
                // errorMessage={formikProps.touched.password && formikProps.errors.password ? formikProps.errors.password : ""}
              />
              <Input
                variant="bordered"
                name="passwordConfirm"
                label="Подтвердите пароль"
                placeholder="Введите повторно пароль"
                value={formikProps.values.password}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                endContent={
                  <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
                    {isVisible ? (
                      // <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      <img src={EyeIc}/>
                    ) : (
                      // <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      <img src={EyeIc}/>
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                //@ts-ignore
                // errorMessage={formikProps.touched.password && formikProps.errors.password ? formikProps.errors.password : ""}
              />
            </div>
            <div style={{marginTop:'20px'}}/>
            {/* @ts-ignore */}
            <Button size="lg" color="primary" onPress={handleSubmit}>
            Сбросить пароль
            </Button>

          </Form>
        }
        <BottomBlock>
          <HelpButton>
            <img src={IcFaq}/>
            <HelpText>
              FAQ
            </HelpText>
          </HelpButton>
          <HelpButton>
            <img src={TelegaIc}/>
            <HelpText>
              Telegram
            </HelpText>
          </HelpButton>
        </BottomBlock>
      </RightBlock>
    </Container>
  )
}
